.title-tenant {
  background-color: #6b7280;
  border-radius: 25px;
  width: 100%;
  min-width: 195px;
  max-width: 195px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  margin-left: -7px;
  cursor: pointer;
}

.tenant-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.add-tenant {
  display: flex;
  width: 100%;
  padding: 10px 20px 0px 20px;
  cursor: pointer;
}

.title-tenant-image-and-title {
  display: flex;
  align-items: center;
}

.title-tenant-image-and-title > * {
  margin: 0px 6px;
}

.branch-dialog-select {
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  width: 350px;
}

.branch-dialog-menu-list {
  height: 225px;
  overflow-y: auto;
  margin-top: 8px;
}

.ant-divider-horizontal {
  margin: 8px !important;
}

.ant-btn-text {
  color: #0050b3 !important;
  font-weight: 600 !important;
}

// .ant-btn > .anticon {
//   color: #0050b3 !important;
// }

.parent-side {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  background-color: #374251;
  padding: 5px;
  min-height: 100vh;
  width: 85px;
  margin-left: -5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.menu-parent-side {
  width: 60px;
}

.logo {
  height: 30px;
  margin: 16px;
  margin-left: 24px;
}

.site-layout .site-layout-background {
  background: #fff !important;
  padding: 0 !important;
}

.select-branch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}

.superadmin-button {
  background-color: #1f2937 !important;
  border: 1px solid white !important;
  width: 100%;
}

.select-branch-div {
  margin-left: 20px;
}

.select-branch-text {
  color: white !important;
  margin-bottom: 10px !important;
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.title-text {
  color: white !important;
  font-size: 28;
  // margin-bottom: 5px !important;
  // margin-bottom: 20px !important;
}

.ant-layout-sider {
  background-color: #4b5663 !important;
  // background-color: #1f2937 !important;
  // padding: 10px;
}

.select-branch-text-bold {
  color: white !important;
  margin-bottom: 10px !important;
  font-weight: 600;
  width: 100%;
  text-align: left;
}

.branchSearch {
  margin-top: -1px !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

// .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
//   border-top-right-radius: 8px !important;
//   border-bottom-right-radius: 8px !important;
// }

// .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
//   border-top-left-radius: 8px !important;
//   border-bottom-left-radius: 8px !important;
//   border: none;
//   background: #f9fafb;
// }

// .anticon {
//   color: #d1d5db !important;
// }

input.ant-input.ant-input-lg::placeholder {
  color: #d1d5db !important;
  font-size: 15px;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  background: #f9fafb !important;
}

.ant-input-group-addon {
  display: none !important;
}

.select-branch-button {
  padding: 0px !important;
  text-align: left !important;
  padding-left: 10px !important;
  border-radius: 7px !important;
  background-color: rgba(107, 114, 128, 1) !important;
  border-color: rgba(107, 114, 128, 1) !important;
  height: 50px !important;
  width: 95%;
  min-width: 175px;
  max-width: 175px;
  margin-bottom: 5px;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    margin-bottom: -5px;
  }
}

.ant-layout-sider-children,
.ant-layout-sider-children .ant-menu {
  // background-color: #2e4d77 !important;
  background-color: transparent !important;
}

.ant-menu-item.sidebar-menu {
  margin-left: -15px !important;
  color: rgba(209, 213, 219, 1);
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-style: normal;
  font-weight: normal;
}

.ant-menu-submenu.ant-menu-submenu-inline {
  margin-left: -15px !important;
  color: rgba(209, 213, 219, 1);
  font-style: normal;
  font-weight: normal;
}

.ant-menu-item.ant-menu-item-only-child {
  color: rgba(209, 213, 219, 1);
  font-style: normal;
  font-weight: normal;
}

.ant-menu-item.ant-menu-item-active.sidebar-menu {
  color: #fff;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active {
  color: #fff;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active {
  color: #fff;
}

.branch-item {
  color: #000000 !important;
}

.branch-select-item-selected {
  background: #f1f5f9 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: bold;
}

.branch-select-item {
  display: flex !important;
  justify-content: space-between !important;
}

.branch-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #f1f5f9 !important;
}

// .ant-menu-inline,
// .ant-menu-vertical,
// .ant-menu-vertical-left {
//   border-right: 1px solid transparent !important;
// }

.ant-menu-item {
  border-radius: 8px !important;
  min-height: 50px;
  padding-top: 6px !important;
}
.ant-menu-item-selected {
  background-color: transparent !important;
  color: #fff;
  border-radius: 0px !important;
  margin: 10px;
  padding-top: 6px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.ant-menu-item-selected.parent-menu {
  background-color: #6b7380 !important;
  color: #fff;
  border-radius: 0px 7px 7px 0px !important;
  margin: 10px;
  margin-top: 24px !important;
  padding-top: 6px !important;
  font-style: normal;
  font-weight: 600 !important;
}

.ant-menu-item-selected::before {
  content: '';
  height: 45%;
  width: 2px;

  position: absolute;
  left: 0;
  bottom: 13.5px;

  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-menu-item-selected.parent-menu::before {
  content: '';
  height: 45%;
  width: 2px;

  position: absolute;
  left: 0;
  bottom: 13.5px;

  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.ant-menu-item.parent-menu {
  // border-radius: 0px !important;
  margin: 10px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px !important;
  padding-bottom: 8px !important;
}

// .ant-layout-sider-children .ant-menu-item-selected {
//   background-color: #6b7280 !important;
//   border-radius: 8px;
//   margin: 10px;
// }

.site-layout-background {
  background: #fff !important;
  padding: 0 !important;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent !important;
}

.ant-layout-sider-trigger {
  background-color: #1f2937 !important;
}

.ant-layout-sider-children {
  background-color: #4b5663 !important;
  // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
}

// .ant-menu-item .anticon + span,
// .ant-menu-submenu-title .anticon + span {
//   color: #fff !important;
// }

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff !important;
}

.ant-menu-horizontal {
  background: #fff !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.right-container {
  display: flex;
  align-items: center;
}

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px !important;
}

.ant-menu-submenu-title {
  height: 40px !important;
}

.user-name {
  color: #000000;
  margin-left: 5px;
}

.right-sub-menu-title {
  display: flex;
  color: #999999;
  margin-right: 4;
}

.ant-layout-sider {
  width: 270px !important;
  min-width: 270px !important;
  max-width: 270px !important;
  display: unset;
}
