.ant-btn-group,
.ant-btn {
  border-radius: 7px !important;
}

.ant-layout {
  background-color: white !important;
}

.ant-table-wrapper {
  padding: 20px !important;
}

.ant-card {
  box-shadow: var(--p-card-shadow, 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)) !important;
  border-radius: 10px !important;
  border: unset !important;
  outline: 0.1rem solid transparent;
}
.ant-modal-header {
  border-radius: 10px !important;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.ant-collapse-borderless {
  background-color: transparent !important;
}

.phone-with-country-code:hover {
  border-color: #1f6cbf !important;
  border-right-width: 1px !important;
}

.phone-with-country-code:focus {
  border-color: #1f6cbf !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px fade(#1f6cbf, 20%) !important;
}

.ant-form-item .ant-upload {
  width: 100% !important;
}

.ant-popover {
  z-index: 98 !important;
}

// .ant-picker:focus,
// .ant-picker-focused,
// .ant-input-number:focus,
// .ant-input-number-focused,
// .ant-input:focus,
// .ant-input-focused {
//   border: unset !important;
//   box-shadow: unset !important;
// }

// .ant-picker:focus,
// .ant-picker-focus,
// .ant-input-number:focus,
// .ant-input-number-focus,
// .ant-input:focus,
// .ant-input-focus {
//   border: unset !important;
//   box-shadow: unset !important;
// }

// .ant-picker,
// .ant-input-number,
// .ant-input {
//   border-color: #eceff1 !important;
//   background-color: #eceff1 !important;
//   border-radius: 8px !important;
//   padding: 10px !important;
//   border: unset !important;
// }
// .ant-input-number:focus,
// .ant-input-number-focused {
//   border: unset !important;
//   box-shadow: unset !important;
// }
// .ant-input-number:focus,
// .ant-input-number-focus {
//   border: unset !important;
//   box-shadow: unset !important;
// }

// .ant-input-number {
//   border-color: #eceff1 !important;
//   background-color: #eceff1 !important;
//   border-radius: 8px !important;
//   padding: 10px !important;
//   border: unset !important;
// }

// .ant-input-number {
//   padding: 6px !important;
// }

// .ant-picker {
//   padding: 10px !important;
// }

// .ant-input-number {
//   overflow: hidden;
// }

// .ant-picker,
// .ant-input-number-handler {
//   background-color: #eceff1 !important;
// }

// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border: unset !important;
//   box-shadow: unset !important;
//   height: unset !important;
// }

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border-color: #eceff1 !important;
//   background-color: #eceff1 !important;
//   border-radius: 8px !important;
//   padding: 10px !important;
//   border: unset !important;
//   height: unset !important;
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  // font-family: 'Inter', sans-serif !important;
  letter-spacing: -0.09px;
  font-family: 'Poppins', sans-serif !important;
  // font-family: 'Montserrat', sans-serif !important;
}
